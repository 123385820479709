<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('news.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-divider class="mx-3" vertical />
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('news.head_list2') }} </v-card-title>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.title" :label="$t('news.header_input1')" outlined dense />
            </v-col>
            <v-col cols="12" xs="12" md="10" lg="8">
              <v-text-field v-model="form.detail" :label="$t('news.header_input2')" outlined dense />
            </v-col>
            <!-- <v-col cols="12" xs="12" md="6" lg="4">
              <v-select  label="สถานะ" :items="statusItems" value="value" outlined dense/>
            </v-col> -->
            <v-col cols="12" xs="12" md="6" lg="4">
              <v-text-field v-model="form.image" :label="$t('news.header_input3')" outlined dense />
              <!-- <v-file-input
              v-model="form.image"
                label="รูปภาพ"
                placeholder="Choose File"
                :prepend-icon="null"
                messages="รูปภาพ (700x200)"
                outlined
                dense
              /> -->
            </v-col>
          </v-row>
        </v-card-actions>
        <v-divider class="mx-3" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'news' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">{{ $t('back') }}</span>
          </v-btn>
          <v-btn color="success" @click="save" class="px-3">
            <v-icon>mdi-content-save</v-icon>
            <span class="px-3" :loading="loading">{{ $t('save') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
export default {
  data() {
    return {
      breadcrumbsItems: [
        {
          text: this.$t('news.head'),
          disabled: false,
          href: '/news',
        },
        {
          text: this.$t('news.create'),
          disabled: true,
          href: '',
        },
      ],
      form: {
        title: '',
        detail: '',
        image: '',
        // image: null
      },
      statusItems: [
        {
          text: this.$t('open'),
          value: 'open',
        },
        {
          text: this.$t('close'),
          value: 'close',
        },
      ],
      loading: false,
    }
  },
  async created() {
    this.addLogPage()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'ประกาศ',
            url: window.location.href,
            detail: 'เพิ่มประกาศ',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
        
      }
    },
    async save() {
      this.loading = true
      try {
        await this.$store.dispatch('addNews', this.form)
        this.$swal.fire(this.$t('news.alert_complete'), '', 'success')
        this.$router.push({ name: 'news' })
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message[0],
          })
        }
      }
      this.loading = false
    },
  },
}
</script>
